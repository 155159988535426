enum ERateUnit {
    DAY = 'Day',
    MONTH = 'Month',
    YEAR = 'Year',
    OVERALL = 'Overall',
}

export type MicrocreditDeal = {
    created: Date;
    finalOffer?: {
        amount: number;
        expectedCloseDate: Date;
        issuedAt: Date;
        rate: number;
        rateUnit: string;
        regularPayment: number;
        regularPaymentCount: number;
        regularPaymentDay: Date;
        term: string;
    };
    offer?: {
        term: string;
        amount: number;
        rate: number;
        rateUnit: ERateUnit;
        regularPayment: number;
        regularPaymentTerm?: string;
        mfoOfferId: string;
    };
    id: string;
    isArchived: boolean;
    organizationId: string;
    status: string;
    step: string;
    buttonText?: string;
    updated: Date;
};

type MicrocreditFormWithShortTerm = {
    amount?: number;
    shortTerm?: string;
    buttonText: string;
    term?: never;
};

type MicrocreditFormWithTerm = {
    amount?: number;
    term?: string;
    buttonText: string;
    shortTerm?: never;
};

export type MicrocreditForm = MicrocreditFormWithShortTerm | MicrocreditFormWithTerm;

export type MicrocreditsDto = {
    form?: MicrocreditForm;
    deals: MicrocreditDeal[];
};

export enum MicrocreditStatus {
    Issued = 'issued',
    Created = 'created',
}

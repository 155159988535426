import cn from 'classnames';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { memo, useEffect, useState } from 'react';
import { StickyContainer } from 'react-sticky';

import { useDeviceInfoContext, useIsMobile } from '@sravni/react-utils';

import { PageHeaderSponsor } from '@src/components/PageHeader';
import { ProductList as ProductListCommon } from '@src/components/ProductList';
import { usePageContext } from '@src/config/PageContext';
import { Experiments } from '@src/constants/abTest';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { DEFAULT_LIMIT, FILTERS_NAMES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { SortPanel } from '@src/containers/list/ProductList/SortPanel';
import type { IFilter } from '@src/hooks/useFilters';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { isMainPage, isNaKartuPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { ShowMoreButton } from './ShowMoreButton';
import styles from './styles.module.scss';

interface IProps {
    className?: string;
    pathname?: string;
    filter: IFilter;
    showMoreButton?: ReactNode;
}

// eslint-disable-next-line complexity
const ProductList: React.FC<IProps> = memo(({ className, pathname, filter, showMoreButton }) => {
    const { listKey, sortPanelVisible } = usePageContext();
    const { items, total = 0, loadingMore } = useSelectCreditsList(listKey);
    const { query, asPath } = useRouter();
    const filterValue = filter.filters[FILTERS_NAMES.OFFERS_TYPE];
    const offerType = filterValue || 'new';
    const isMobile = useIsMobile();
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const [pathWithoutQuery] = asPath.split('?');
    const isNewCardDesign = isNaKartuPage(pathWithoutQuery);

    const limit = filter.filters[FILTERS_NAMES.LIMIT] || DEFAULT_LIMIT;
    const activeElements = items?.length || 0;
    const loadItemCount = total - activeElements;
    const preloadTotal = loadItemCount > limit ? limit : loadItemCount;
    const isMain = Boolean(pathname && isMainPage(pathname, query));
    const isNonSeoPage = isMain || listKey !== ListKeys.LIST;
    const isRenderShowMoreButton: boolean =
        (preloadTotal && activeElements) > 0 && [total, !loadingMore, total !== activeElements].some(Boolean);

    const [isRenderRedirectMoreOffersButton, setIsRenderRedirectMoreOffersButton] = useState({
        asPath,
        isRender: isNonSeoPage ? false : !isRenderShowMoreButton,
    });

    useEffect(() => {
        if (isNonSeoPage) {
            setIsRenderRedirectMoreOffersButton({ asPath, isRender: false });
            return;
        }
        const pathWasChanged = isRenderRedirectMoreOffersButton.asPath !== asPath;
        if (pathWasChanged || isRenderShowMoreButton) {
            setIsRenderRedirectMoreOffersButton({ asPath, isRender: !isRenderShowMoreButton });
        }
    }, [isRenderRedirectMoreOffersButton.asPath, isRenderShowMoreButton, asPath, isNonSeoPage, listKey]);

    const hasUtmLabel = useHasUtmLabel();

    const { phone: isPhone } = useDeviceInfoContext();
    const { isAVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);
    const hasFullDeal = checkIsFullDealBlockVisible(pathWithoutQuery);
    const isFullDealBlockVisible = isPhone && isAVariant && hasFullDeal && !hasUtmLabel;

    const isSortPanelShowed =
        sortPanelVisible &&
        Boolean(items?.length) &&
        offerType === 'new' &&
        !(isNewCardDesign && isMobile) &&
        !isFullDealBlockVisible;

    return (
        <StickyContainer id={PRODUCT_LIST_ANCHOR} className={cn(styles.sticky_container, className)}>
            {isMobile && (
                <section className={styles.page_header}>
                    <PageHeaderSponsor />
                </section>
            )}

            {isSortPanelShowed && <SortPanel filter={filter} hasSortLabel />}

            <ProductListCommon preloadTotal={preloadTotal} filter={filter} />

            {isRenderShowMoreButton &&
                !isSpecial &&
                (showMoreButton || (
                    <ShowMoreButton
                        className={styles.show_more}
                        listKey={listKey}
                        preloadTotal={preloadTotal}
                        loadItemCount={loadItemCount}
                    />
                ))}
        </StickyContainer>
    );
});

export default ProductList;
